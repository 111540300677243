@import '../../../styles/variables.scss';

.container {
    position: relative;
    z-index: 1;
    padding: 5px;
    margin-right: 8px;
    color: $lightGrey;
    font-family: "Raleway";
    font-size: $fsBig;
    cursor: pointer;
    letter-spacing: 2px;
}

.container:hover {
    color: $lightGrey70;
}