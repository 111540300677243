@import "../../../styles/variables.scss";

/*  BIG DESKTOP */

/* { */

.main-container-generic {
    position: relative;
    /* min-height: 80vh; */
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    scroll-behavior: smooth;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start !important;
}

.generic-main-left {
    align-items: flex-start;
    background-position: 100% 7vh;
}

.generic-main-right {
    align-items: flex-end;
    background-position: 0px 1vh;
}

.main-container-generic-padding {
    padding-top: 8vh;
    padding-bottom: 8vh;
}

/* TEXT MAIN CONTAINER */

.generic-text-main-container {
    margin-top: 4vh;
    flex-direction: column;
    width: 55%;
}

/* IMAGE SIDE */

.generic-left-image-container, .generic-right-image-container {
    transition: transform 0.6s ease-out, opacity 1.8s ease-out;
    width: 45%;
}

.generic-right-image-container {
    transform-origin: center right;
}

.generic-left-image-container {
    transform-origin: center left;
}

.generic-image {
    width: 40vw;
}

/* --------------------------------------------------------------------------------- */

/* RIGHT GENERAL TITLE AND CONTENT SIZE */

.generic-main-right.main-container-generic-padding {
    padding-left: 0;
    padding-right: 5vw;
    width: calc(100% - (5vw * 1));
}

.generic-main-right .generic-title-container {
    height: min-content;
    width: 90%;
}

.generic-main-right .generic-content-container {
    width: 95%;
}



.generic-main-right .generic-bottom-button-container {
    width: 77%;
}

/* LEFT GENERAL TITLE AND CONTENT SIZE */

.generic-main-left.main-container-generic-padding {
    padding-right: 0 !important;
    padding-left: 13vw;
    width: calc(100% - (13vw * 1));
}

.generic-main-left .generic-title-container {
    height: min-content;
    width: 90%;
}

.generic-main-left .generic-content-container {
    width: 95%;
}

.generic-main-left .generic-content-container>p {
    width: 90%;
}

/* TITLE */

.generic-title-container {
    z-index: 101;
    margin-right: 5px;
    font-size: calc(60% * 1.85);
}

.generic-title-container h1 {
    white-space: nowrap;
    opacity: 1;
    margin: 0;
    /* ANIMATION TIME */
    transition: transform 0.3s ease-out, opacity 1.2s linear;
}

/* --------------------------------------------------------------------------------- */

/* SUBTITLE */

.generic-subtitle-container {
    width: 100%;
    z-index: 101;
}

.generic-subtitle-container h3 {
    opacity: 1;
    /* ANIMATION TIME */
    transition: transform 0.45s ease-out, opacity 1.3s linear;
}

/* --------------------------------------------------------------------------------- */

/* BODY */

.generic-main-right p {
    text-justify: newspaper !important;
    text-align: left;
    margin-top: $veryLargeMargin;
}

.generic-content-container p {
    line-height: 2em;
    opacity: 1;
    /* ANIMATION TIME */
    transition: transform 0.8s ease-out, opacity 3s linear;
    margin-top: $veryLargeMargin;
}

/* --------------------------------------------------------------------------------- */

/* BUTTON */

.generic-bottom-button-container {
    margin-top: 5vh;
    width: 100%;
}

/* left */

.generic-main-left .generic-bottom-button-container {
    justify-content: flex-start;
}

/* right */

.generic-main-right .generic-bottom-button-container {
    justify-content: flex-start;
}

/* --------------------------------------------------------------------------------- */

/* SHOW COMPONENT ANIMATION */

.generic-main-left.main-container-generic-hide h1 {
    transform: translate(-150vh, -50%) scale(0.7);
    opacity: 0;
}

.generic-main-left.main-container-generic-hide h3 {
    transform: translate(-150vh, -50%) scale(0.7);
    opacity: 0;
}

.generic-main-left.main-container-generic-hide p {
    transform: translate(-150vh, -50%) scale(0.7);
    opacity: 0;
}

.main-container-generic-hide .generic-left-image-container, .main-container-generic-hide .generic-right-image-container {
    transform: translate(0, 12%) scale(0.6);
    opacity: 0;
}

.generic-main-right.main-container-generic-hide h1 {
    transform: translate(150vh, -50%) scale(0.7);
    opacity: 0;
}

.generic-main-right.main-container-generic-hide h3 {
    transform: translate(150vh, -50%) scale(0.7);
    opacity: 0;
}

.generic-main-right.main-container-generic-hide p {
    transform: translate(150vh, -50%) scale(0.7);
    opacity: 0;
}

/* --------------------------------------------------------------------------------- */

/* } */

/* SUPER WIDE DESKTOP */

/* { */

@media (min-aspect-ratio: 19/9) {
    .generic-text-main-container {
        transform: scale(0.8)
    }
    .generic-main-left .generic-text-main-container {
        transform-origin: center left;
    }
    .generic-main-right .generic-text-main-container {
        width: 50%;
        transform-origin: center right;
    }
    .generic-left-image-container>.generic-image, .generic-right-image-container>.generic-image {
        transform: scale(0.8) !important;
    }
    .generic-left-image-container>.generic-image {
        transform-origin: center left;
    }
    .generic-right-image-container>.generic-image {
        transform-origin: center right;
    }
}

/* } */

/* MEDIUM BIG DESKTOP */

/* { */

@media only screen and (max-width: 1800px) and (min-width: 1434px) {
    // .main-container-generic {
        /* width: calc(100% - (10vw * 2)); */
    // }
    /* PADDING LEFT MODE */
    .generic-main-left.main-container-generic-padding {
        padding-right: 0 !important;
        padding-left: 13vw;
        width: calc(100% - (13vw * 1)) !important;
    }
    /* PADDING RIGHT MODE */
    .generic-main-right.main-container-generic-padding {
        padding-right: 4vh !important;
        padding-left: 0;
        width: calc(100% - (4vw * 1)) !important;
    }
    .generic-content-container, .generic-title-container {
        width: 70%;
    }
    .generic-subtitle-container {
        width: 100%;
    }
 
}

/* } */

/* MEDIUM DESKTOP */

/* { */

@media only screen and (max-width: 1433px) and (min-width: 1280px) {
    .main-container-generic {
        width: calc(100% - (10vw * 2));
    }
    .main-container-generic-padding {
        padding-top: 8vh;
        padding-bottom: 8vh;
        padding-right: 10vw;
        padding-left: 10vw;
    }
    .generic-content-container, .generic-title-container {
        width: 70%;
    }
 
}

/* } */

/* SMALL PC */

/* { */

@media only screen and (max-width: 1280px) and (min-width: 992px) {
    .main-container-generic {
        overflow-x: hidden;
        width: calc(100% - (8vw * 2));
        align-items: flex-start;
    }
    .generic-main-right {
        width: calc(100% - 8vw);
        padding-right: 0vw !important;
    }
    .generic-main-right .generic-content-container {
        width: 67%;
    }
    .main-container-generic-padding {
        padding-top: 7.5vh;
        padding-bottom: 7.5vh;
        padding-right: 8vw;
        padding-left: 8vw;
    }
    .generic-content-container, .generic-title-container {
        width: 88%;
    }
    .generic-title-container h1 {
        width: fit-content !important;
        margin-right: 0px !important;
    }
    .generic-subtitle-container h3 {
        width: fit-content !important;
    }
    .generic-content-container p {
        width: 100%;
    }
}

/* SUB BREAKPOINT PER SPOSTAMENTO SFONDO */

/* @media only screen and (max-width: 1100px) and (min-width: 992px) {
    .generic-main-left {
    }
} */

/* } */

/* SUPER SMALL PC */

/* { */

@media only screen and (max-width: 991px) and (min-width: 801px) {
    .main-container-generic {
        overflow-x: hidden;
        width: calc(100% - (8vw * 2));
        background-size: 700px;
    }
    .generic-main-right {
        background-size: 500px !important;
    }
    .generic-main-right {
        width: calc(100% - 8vw);
        background-position: 0 7vh;
        background-size: 700px !important;
        padding-right: 0vw !important;
    }
    .generic-main-right .generic-content-container {
        width: 60%;
    }
    .generic-main-right p {
        width: 80% !important;
    }
    .main-container-generic-padding {
        padding-top: 7.5vh;
        padding-bottom: 7.5vh;
        padding-right: 8vw;
        padding-left: 8vw;
    }
    .generic-content-container, .generic-title-container {
        width: 90%;
    }
    .generic-title-container h1 {
        font-size: 3.8em;
    }
    .generic-subtitle-container h3 {
        font-size: 1.45em;
    }
    .generic-content-container p {
        width: 87%;
        font-size: 1em;
    }
}

/* } */

/* TABLET */

/* { */

@media only screen and (max-width: 800px) and (min-width: 412px) {
    .main-container-generic {
        overflow-x: hidden;
        width: calc(100% - (2vw * 2));
        min-height: 60vh;
        background-size: 600px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .generic-main-left {
        background-position: 100% 3vh !important;
    }

    .generic-main-right .generic-content-container>p {
        width: 100% !important;
        min-width: 100% !important;
    }
    

    .main-container-generic-padding {
        padding-top: 7.5vh;
        padding-bottom: 7.5vh;
        padding-right: $smallMargin;
        padding-left: 8vw;
    }

    .generic-content-container{
        width: 100% !important;
    }
    .generic-title-container h1 {
        font-size: $fsMega;
    }
    .generic-subtitle-container h3 {
        font-size: 1.1em !important;
    }
    .generic-content-container p {
        width: 100%;
        font-size: 1.2em;
    }
}

/* } */

/* MOBILE VERTICAL */

/* { */

@media only screen and (max-width: 729px) and (orientation: portrait) {
    .main-container-generic {
        width: calc(100% - (5vw * 0)) !important;
        min-height: 40vh;
        background-size: 150vw;
        flex-direction: column;
    }
    .generic-main-left {
        /* SPINGE IL BACKGROUND VERSO DESTRA */
        align-items: flex-end;
        flex-direction: column-reverse;
        background-position: 80% -3vh !important;
    }
    .generic-main-right {
        /* SPINGE IL BACKGROUND VERSO DESTRA */
        background-position: -10px 3vh !important;
    }
    .generic-main-right h1 {
        width: 100% !important;
        margin-top: 5vh;
    }
    .main-container-generic-padding {
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-right: 0vw !important;
        padding-left: 0vw !important;
    }
    /* IMMAGINE */
    .generic-image {
        width: 100vw;
        opacity: 0.06;
    }
    /* CONTAINER TESTO */
    .generic-text-main-container {
        z-index: 100;
        width: calc(100% - (5vw * 2)) !important;
        padding-left: 5vw !important;
        padding-right: 5vw !important;
    }
    /* CONTAINERs IMMAGINE E TESTO */
    .generic-text-main-container, .generic-left-image-container, .generic-right-image-container {
        width: 100%;
    }
    /* CONTAINER IMMAGINI */
    .generic-left-image-container, .generic-right-image-container {
        position: absolute;
        height: fit-content !important;
        overflow: hidden;
    }
    .generic-main-right .generic-bottom-button-container {
        justify-content: flex-end;
    }
    .generic-title-container, .generic-subtitle-container {
        width: 100% !important;
    }
    /* TEXTS */
    .generic-title-container h1 {
        font-size: 2.5em;
        margin-bottom: 35px;
    }
    .generic-subtitle-container h3 {
        font-size: 3.7vw !important;
    }
    .generic-content-container p {
        width: 95%;
        font-size: 1em;
    }
    .generic-bottom-button-container {
        width: 100%;
    }
}

/* } */

/* SMALL MOBILE VERTICAL */

/* { */

@media only screen and (max-width: 412px) and (orientation: portrait) {
    /* CONTAINER TESTO */
    .generic-text-main-container {
        z-index: 100;
        width: calc(100% - (5vw * 1)) !important;
        padding-left: 5vw !important;
        padding-right: 0vw !important;
    }
    .generic-content-container p {
        width: 100%;
        font-size: 0.8em !important;
    }
    .generic-main-right .generic-bottom-button-container {
        justify-content: flex-start;
    }
}

/* } */