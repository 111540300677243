@import "../styles/variables.scss";

  floatright {
    float: right;
  }
  
  dividerhz {
    display: block;
    min-width: 100%;
    min-height: 0px;
    border: solid 1px $red;
    border-radius: $infiniteRadius;
  }
  
  forarts {
    color: $white !important;
    font-family: $helveticaNB;
  }
  
  fs {
    color: $red !important;
    font-family: $helveticaNM;
  }

  fsdark {
    color: $darkRed
  }