@import "../../../styles/variables.scss";
@import "../../../styles/colors.scss";

.generic-button {
    position: relative;
    background-color: $almostBlack;
    color: $notWhite;
    padding: $mediumMargin $largeMargin;
    /* height: 1.5vh; */
    /* WIDTH */
    min-width: (50px + (25px * 2));
    width: fit-content;
    border: solid 1.5px $notWhite;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    /* box-shadow: 3px 1px 20px -6px #5a5a5ac7; */
    cursor: pointer;
    transition: 0.2s;
}

.generic-button-only-icon {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important;
    justify-content: center;
    align-items: center;
}

.generic-button-only-icon:hover {
    color: green !important;
}

.generic-button:hover .button-icon {
    transition: 0.2s;
    color: white;
}

.generic-button:hover {
    background-color: $notWhite;
    color: $almostBlack;
    border: solid 1.5px $notWhite;
}

.generic-button:active {
    background-color: $darkGrey;
    color: $notWhite;
    border: solid 1.5px $darkGrey;
}

/* INSTAGRAM */

div[aria-details="icon-instagram"]:hover .button-icon,
div[aria-details="icon-instagram"]:active .button-icon {
    background: linear-gradient(45deg, #f09433 0%, #fcb045 20%, #fd1d1d 45%, #833ab4 65%);
    -webkit-background-clip: text;
    color: black;
    /*  Also deine standard property for compatibility / */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* FACEBOOK */

div[aria-details="icon-facebook"]:hover .button-icon,
div[aria-details="icon-facebook"]:active .button-icon {
    color: #1877f2;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

/* LINKEDIN ? */

div[aria-details="icon-linkedin"]:hover .button-icon,
div[aria-details="icon-linkedin"]:active .button-icon {
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.generic-button-only-icon.generic-button:active {
    background-color: #000000;
    color: #dddddd;
}

.generic-button-text {
    width: auto;
    font-family: $raleway;
    white-space: nowrap !important;
    font-size: $fsSmall;
    font-weight: 500;
    transition: 0.1s;
}

/* DIVISONE ICONA / TESTO  */

.generic-button-hided-divider {
    width: 40px;
}

/* ICONA */

.button-icon {
    color: #000000e1;
    font-size: 1.3em;
}

/* ANIMATION STYLE */

.generic-button-animation .button-icon {
    position: absolute;
    width: 10%;
    left: 45%;
    color: $white;
    transform: scale(1.3);
    transition: color 1s, left 0.9s, transform 1.2s;
}

.generic-button-animation:hover .button-icon {
    left: 0.5vh;
    color: $darkGrey;
    transform: scale(1);
    transition: color 1s, left 0.35s, transform 0.5s;
    transition-timing-function: ease-out;
}

.generic-button-animation:active .button-icon {
    left: 0.9vh;
    transform: scale(1.2);
    transition: left 0.1s, transform 0.1s;
    color: #aa223a;
    transition-timing-function: ease-in;
}

.generic-button-animation .generic-button-text {
    overflow: hidden;
    opacity: 0;
    transform: scale(0.87);
    transition: opacity 0.3s, transform 0.8s ease-in;
}

.generic-button-animation:hover .generic-button-text {
    transform: scale(1);
    opacity: 1;
    transition: opacity 1s, transform 0.7s ease-out;
}

.generic-button-animation:active .generic-button-text {
    transform: scale(0.75);
    opacity: 0.7;
    transition: opacity 0.1s, transform 0.1s ease-out;
}

/* SMALL MOBILE VERTICAL */

/* { */

@media only screen and (max-width: 729px) and (orientation: portrait) {
    .generic-button {
        margin-right: 10px;
    }
    .generic-button:last-child {
        margin-right: 0px !important;
    }
}

/* } */

/* LARGE PC */

@media only screen and (min-width: $mqPcWide) {
    .generic-button-text {
        font-size: $fsMid;
    }
}
/* } */
