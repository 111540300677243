@import "./variables.scss";


// MARGIN

// All side
.m-1 {
    margin: $microMargin;
}

.m-2 {
    margin: $tinyMargin;
}

.m-3 {
    margin: $smallMargin;
}

.m-4 {
    margin: $mediumMargin;
}

.m-5 {
    margin: $largeMargin;
}

// Top
.mt-1 {
    margin-top: $microMargin;
}

.mt-2 {
    margin-top: $tinyMargin;
}

.mt-3 {
    margin-top: $smallMargin;
}

.mt-4 {
    margin-top: $mediumMargin;
}

.mt-5 {
    margin-top: $largeMargin;
}

// Right
.mr-1 {
    margin-right: $microMargin;
}

.mr-2 {
    margin-right: $tinyMargin;
}

.mr-3 {
    margin-right: $smallMargin;
}

.mr-4 {
    margin-right: $mediumMargin;
}

.mr-5 {
    margin-right: $largeMargin;
}

// Bottom
.mb-1 {
    margin-bottom: $microMargin;
}

.mb-2 {
    margin-bottom: $tinyMargin;
}

.mb-3 {
    margin-bottom: $smallMargin;
}

.mb-4 {
    margin-bottom: $mediumMargin;
}

.mb-5 {
    margin-bottom: $largeMargin;
}

// Left
.ml-1 {
    margin-left: $microMargin;
}

.ml-2 {
    margin-left: $tinyMargin;
}

.ml-3 {
    margin-left: $smallMargin;
}

.ml-4 {
    margin-left: $mediumMargin;
}

.ml-5 {
    margin-left: $largeMargin;
}

// PADDING

// All side
.p-1 {
    padding: $microMargin;
}

.p-2 {
    padding: $tinyMargin;
}

.p-3 {
    padding: $smallMargin;
}

.p-4 {
    padding: $mediumMargin;
}

.p-5 {
    padding: $largeMargin;
}

// Top
.pt-1 {
    padding-top: $microMargin;
}

.pt-2 {
    padding-top: $tinyMargin;
}

.pt-3 {
    padding-top: $smallMargin;
}

.pt-4 {
    padding-top: $mediumMargin;
}

.pt-5 {
    padding-top: $largeMargin;
}

// Right
.pr-1 {
    padding-right: $microMargin;
}

.pr-2 {
    padding-right: $tinyMargin;
}

.pr-3 {
    padding-right: $smallMargin;
}

.pr-4 {
    padding-right: $mediumMargin;
}

.pr-5 {
    padding-right: $largeMargin;
}

// Bottom
.pb-1 {
    padding-bottom: $microMargin;
}
.pb-2 {
    padding-bottom: $tinyMargin;
}

.pb-3 {
    padding-bottom: $smallMargin;
}

.pb-4 {
    padding-bottom: $mediumMargin;
}

.pb-5 {
    padding-bottom: $largeMargin;
}

// Left
.pl-1 {
    padding-left: $microMargin;
}

.pl-2 {
    padding-left: $tinyMargin;
}

.pl-3 {
    padding-left: $smallMargin;
}

.pl-4 {
    padding-left: $mediumMargin;
}

.pl-5 {
    padding-left: $largeMargin;
}