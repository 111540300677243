/* 
TODO
Convertire il file in .scss, importare i file necessari
('variables e cazzi vari se necessari') ed aggiornare l'import nel .tsx
*/
 
 /* 
TODO
Controllare che il css attuale sia a posto da 1800px a 900px
per quanto riguarda la font size del titolo della sezione.
Se non ci siano problemi con òa font size in px (non credo)
buttare via.


 */
 /* @media only screen and (max-width: 1800px) and (min-width: 1433px) {
    .call-title {
        font-size: 3.7vw;
    }
}

@media only screen and (max-width: 1433px) and (min-width: 1280px) {
    .call-title {
        font-size: 3.3vw;
    }
}

@media only screen and (max-width: 1280px) and (min-width: 992px) {
    .call-title {
        font-size: 2.7vw;
    }
} */


/* SMALL MOBILE VERTICAL */

/* 
TODO sostituire dimensioni con le variabili comuni
 */

@media only screen and (max-width: 400px) and (orientation: portrait) {
    .call-title {
        font-size: 9vw !important;
        margin: 0;
        margin-bottom: 50px !important;
    }
    .call-body {
        margin: 0px !important;
        font-size: 0.7em !important;
    }
}

/* } */

