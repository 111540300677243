.flex-center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.j-between {
  justify-content: space-between;
}

.j-around {
  justify-content: space-around;
}

.j-center {
  justify-content: center;
}

.a-center {
  align-items: center;
}

.a-stretch {
  align-items: stretch;
}

.j-start {
  justify-content: flex-start;
}

.a-start {
  align-items: flex-start;
}

.j-end {
  justify-content: flex-end;
}

.a-end {
  align-items: flex-end;
}

.f-column {
  flex-direction: column;
}

.f-row {
  flex-direction: row;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-nowrap {
  flex-wrap: wrap;
}
