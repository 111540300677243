.main-container-header {
    position: fixed;
    z-index: 5000;
    width: 100%;
    height: 65px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.863);
    transform: translateY(0px) !important;
    transition: transform 0.2s ease-in-out, height 0.2s, opacity 0.3s;
}

.hidden {
    opacity: 0;
    transform: translateY(-65px) !important;
}

.env-label {
    color: rgb(216, 216, 216);
    font-family: "Raleway";
    margin-left: 100px;
    margin-top: 7px;
}

.main-row {
    width: calc(100% - (5px * 2));
    padding: 0 5px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-icons-section {
    /* width: calc(100% - (5px * 2)); */
    position: absolute;
    padding: 0 5px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: transform 0.5s;
}


.text-logo {
    height: 35px;
    margin-left: 20px;
    cursor: pointer;
}

.right-buttons-section {
    position: absolute;
    /* width: calc(50% - (5px * 2)); */
    padding: 0 5px;
    height: 100%;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.icon {
    color: white !important;
    font-size: 1.8em;
    opacity: 0.8;
    transition: opacity 0.2s ease-in;
    cursor: pointer;
    -webkit-user-select: none !important;
    /* Safari */
    -ms-user-select: none !important;
    /* IE 10+ and Edge */
    user-select: none !important;
    /* Standard syntax */
    /* width: 35px;
    height: 35px;
    display: flex;
    align-items: center; */
}

.icon:hover {
    opacity: 1;
}

.icon-wrapper {
    margin: 0 15px;
    -webkit-user-select: none !important;
    /* Safari */
    -ms-user-select: none !important;
    /* IE 10+ and Edge */
    user-select: none !important;
}

.menu-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mobile-row {
    display: none;
    transition: transform 0.5s ease-in-out, opacity 0.3s;
}

/* MOBILE */

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .mobile-header-phisic {
        height: 65px
    }
    .main-row {
        width: calc(100% - (5px * 2));
        height: 50%;
        z-index: 600;
        background-color: black;
    }
    .mobile-row {
        z-index: 599;
        overflow-x: auto;
        width: calc(100% - (12px + 20vw)) !important;
        z-index: 900;
        padding: 0 20vw 0 12px;
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .main-container-header.hidden .main-row {
        height: 100%;
    }
    .main-container-header.hidden {
        justify-content: flex-start;
        opacity: 1 !important;
        transform: unset !important;
        height: calc(65px * 1);
    }
    .main-container-header {
        width: calc(100%);
        padding: 0 0vw;
        height: calc(65px * 2);
    }
    .env-label {
        display: none !important;
    }
    .icon-wrapper {
        margin: 0 0px
    }
    .button-section {
        display: none;
    }
    .text-logo {
        height: 28px;
        margin-left: 8px;
        cursor: pointer;
    }
    .logo-down {
        transform: translateY(0px);
    }
    .env {
        transform: translateY(65px);
    }
    .right-buttons-section {
        position: absolute;
        width: calc(50% - (5px * 2));
        padding: 0 15px;
        height: 100%;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .logo {
        margin-left: 0 !important;
    }
    .section-button:last-child {
        padding-right: 50vw !important;
    }
    .section-button {
        /* min-width: calc(33% - (3vw * 2) - (10px * 2)); */
        padding: 0 3vw;
        color: white;
        font-size: 3.1vw !important;
        letter-spacing: 1.5px;
        white-space: nowrap;
        margin: 0 10px !important;
        font-weight: 500;
        font-family: 'Raleway';
        cursor: pointer;
        transition: transform 0.3s;
        transform: scale(1.0);
    }
    .header-shadow-cover {
        position: absolute;
        min-width: 80%;
        right: 0px !important;
        min-height: inherit;
        max-height: inherit;
        height: inherit;
        background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.63));
        pointer-events: none !important;
        z-index: 1000;
    }
}

@media only screen and (max-height: 768px) and (orientation: landscape) {
    .env-label {
        display: none;
    }
}

.button-section {
    padding-top: 4px
}

/* BOTTONE DI NAVIGAZIONE */

.section-button {
    color: white;
    font-size: 1.1em;
    letter-spacing: 1.5px;
    margin: 0 23px;
    font-weight: 500;
    font-family: 'Raleway';
    cursor: pointer;
    transition: transform 0.3s;
    transform: scale(1.0);
}

.section-button:hover {
    transform: scale(1.18);
}