// MARGINS
$microMargin: 2px;
$tinyMargin: 4px;
$smallMargin: 8px;
$mediumMargin: 15px;
$largeMargin: 25px;
$veryLargeMargin: 50px;
$gigaMargin: 75px;
$ultraLargeMargin: 100px;
$maxMargin: 200px;

// BORDER RADIUS
$tinyRadius: 5px;
$smallRadius: 12px;
$mediumRadius: 20px;
$largeRadius: 40px;
$infiniteRadius: 100000px;

// FONT FAMILIES
$raleway: 'Raleway';
$helveticaNB: 'HNB';
$helveticaNM: 'HNM';
$helveticaN: 'HN';

// FONT SIZES 
$fsMini: 10px;
$fsXXS: 12px;
$fsSmall: 14px;
$fsMid: 16px;
$fsLarge: 19px;
$fsBig: 22px;
$fsXL: 24px;
$fsXXL: 28px;
$fsMega: 34px;
$fsMegaXL: 40px;
$fsGiga: 45px;
$fsUltra: 65px;
$fsMAX: 85px;

// FONT WEIGHTS
$fw900: 900;
$fw600: 600;
$fw500: 500;
$fw400: 400;

// LINE HEIGHTS
$contentLineHeight: 2em;
$aboutLineHeight: 2.2em;
// COLORS
$white: white;
$notWhite: rgb(240, 240, 240);

$black: black;
$almostBlack: rgb(23, 23, 23);
$notBlack: rgb(38, 38, 38);

$red: #aa223a;
$mediumRed: #8b0d2a;
$darkRed: #620a1e;
$deepRed: #3d0613 ;

$darkGrey: #3c3c3c;
$darkGrey70: #3c3c3cd3;
$darkGrey50: #3c3c3c80;

$lightGrey: #707070;
$lightGrey70: #707070b0;
$lightGrey50: #70707072;

// BOX SHADOWS
$wideContainerShadow: 0 0 15px -5px $lightGrey50;
$wideHoverContainerShadow: 0 0 10px 0 $lightGrey50;
$narrowContainerShadow: 0 0 10px -5px $lightGrey50;
$narrowHoverContainerShadow: 0 0 5px 0 $lightGrey50;

// TRANSITIONS
$mediumTransition: 0.3s;


// BREAKPOINTS
$mqPcWide: 2100px;