@import "./variables.scss";

.cc-red {
color: $red;
}

.cc-notWhite {
    color: $notWhite;
}

.cc-white {
    color: $white;
}

.cc-midred {
color: $mediumRed;
}

.bg-notBlack {
    background-color: $notBlack;
}

.bg-almostBlack {
    background-color: $almostBlack;
}

.bg-darkGrey {
    background-color: $darkGrey;
}