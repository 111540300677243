@import "./variables.scss";

// HEIGHTS
.h-100 {
    height: 100%;
}

// CURSOR POINTER 
.c-pointer {
    cursor: pointer;
}

// POSITIONS
.relative {
    position: relative;
}

// RELEASE DATE
.releaseDate {
    font-family: $raleway;
    font-size: $fsSmall;
    color: $lightGrey;
    margin: $mediumMargin 0;
}

// HEADINGS & PARAGRAPHS

h1 {
    font-family: $helveticaNB;
    font-weight: $fw900;
    font-size: $fsUltra;
}

h2 {
    font-family: $helveticaNB;
    font-size: $fsGiga;
    font-weight: $fw600;
}

h3 {
    font-family: $helveticaNM;
    font-size: $fsMega;
    font-weight: $fw500;
}

h4 {
    font-family: $helveticaNM;
    font-weight: $fw900;
    font-size: $fsGiga;
    letter-spacing: $microMargin;
    color: $white
}

h5 {
    font-family: $helveticaNB;
    font-size: $fsBig;
    margin: 0;
}

h6 {
    font-family: $helveticaN;
    font-size: $fsLarge;
    font-weight: $fw500;
    margin: 0;
}


p {
    font-family: $raleway;
    font-size: $fsMid;
    font-size: $fw400;
}
h1,
h2,
h3,
h5,
h6,
p {
    color: $notWhite;
}

h1 b,
h2 b,
h3 b,
h4 b {
    color: $red;
}


@media only screen and (min-width: $mqPcWide) {
    p {
        font-size: $fsLarge;
    }
}