@import "../../../styles/variables.scss";
@import "./footer-utils.scss";

.main-container-footer {
    width: calc(100% - (3vw + 2vw));
    min-height: $footerMinHeight;
    max-height: $footerMaxHeight;
    padding: 2vh 3vw 2vh 2vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.webp .main-container-footer {
    background-image: url("../../../assets/images/footer-bg.webp");
}

.no-webp .main-container-footer {
    background-image: url("../../../assets/images/footer-bg.jpg");
}

.mail {
    font-family: $raleway;
    font-weight: $fw400;
    font-size: $fsMid;
}

.legal {
    font-family: $raleway;
    font-size: $fsSmall;
}

.footer-logo {
    height: $footerLogoHeight;
}

.link {
    font-family: $raleway;
    font-size: $fsBig;
    font-weight: $fw400;
    cursor: pointer;
    transition: color 0.3s;
}

.footer-link:hover {
    color: $mediumRed;
}

.footer-link:active {
    transition: color 0.1s;
    color: $red;
}

/* } */

/* SMALL PC */

/* { */

@media only screen and (max-width: 1315px) and (min-width: 886px) {
    .footer-mail {
        font-size: 0.9em;
    }
    .footer-left-title {
        font-size: 2.5em;
    }
    .center-wrapper-footer {
        font-size: 0.6em;
    }
    .right-wrapper-footer {
        font-size: 1.2em;
    }
}

/* } */

/* IPAD PRO / IPAD /IPAD-MINI / KINDLE / NEXUS10 */

/* { */

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .main-container-footer {
        flex-direction: row;
        align-items: center;
        max-height: unset !important;
        flex-wrap: wrap;
        justify-content: center;
    }
    .link {
        width: 30%;
        text-align: right !important;
        align-items: flex-end;
    }
    .contacts {
        width: 30%;
        text-align: left;
    }
    .footer-logo {
        height: 7vh !important;
    }
    .footer-mail {
        line-height: 3vh !important;
    }
    .center-wrapper-footer {
        width: 38%;
        text-align: center;
        font-size: 0.5em !important;
    }
    .right-wrapper-footer {
        line-height: 4vh !important;
    }
    .legal-info {
        margin-top: 4vh !important;
    }
}

/* NEXUS7 */

/* { */

@media only screen and (max-width: 768px) and (min-width: 500px) {
    .main-container-footer {
        flex-direction: column-reverse;
        align-items: center;
        max-height: unset !important;
        flex-wrap: wrap;
        justify-content: center;
    }
    .link {
        width: 75%;
        text-align: center !important;
        align-items: center;
    }
    .contacts {
        width: 80%;
        text-align: center;
    }
    .footer-mail {
        line-height: 5vh !important;
        font-size: 0.6em !important;
    }
    .footer-left-title {
        font-size: 1.75em;
    }
    .center-wrapper-footer {
        font-size: 0.4em !important;
    }
    .right-wrapper-footer {
        line-height: 5vh !important;
        font-size: 0.8em !important;
    }
    .footer-logo {
        height: 6vh !important;
        margin-top: $mediumMargin;
    }
    .legal {
        margin-top: 4vh !important;
        text-align: center;
    }
}

/* MOBILE */

/* { */

@media only screen and (max-width: 499px)  {
    .main-container-footer {
        flex-direction: column-reverse;
        align-items: center;
        max-height: unset !important;
        flex-wrap: wrap;
        justify-content: center;
    }
    .link {
        width: 75%;
        text-align: center !important;
        align-items: center;
    }
    .contacts {
        width: 80%;
        text-align: center;
    }
    .footer-mail {
        font-size: 0.4em !important;
        margin: 0 5vw;
        letter-spacing: 0px;
    }

    .footer-mail-wrapper {
        flex-direction: row !important;
        justify-content: space-around !important;
        align-items: center !important;
    }
    .footer-left-title {
        font-size: 0.9em;
        letter-spacing: 0.7px;
    }
    .left-wrapper-footer {
        align-items: center !important;
        flex-direction: column;
    }
    .center-wrapper-footer {
        font-size: 0.4em !important;
        align-items: center !important;
        text-align: center;
        margin: 2vh 0;
        width: 80%;
    }
    .right-wrapper-footer {
        line-height: 3vh !important;
        align-items: center !important;
        font-size: 0.6em !important;
        width: 100%;
        flex-direction: row !important;
    }
    .footer-logo {
        height: 5vh !important;
        margin-top: 2vh;
    }
    .legal-info {
        margin-top: 1vh !important;
        padding: 0 1vw;
    }
}
