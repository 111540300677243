.m-main-container {
    position: relative;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 100vh;
    overflow: hidden !important;
}

.main-container-carousel {
    position: relative;
    overflow: hidden !important;
    /* width: 300vw !important; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap !important;
    transition: margin-left 1s;
}

.side {
    position: absolute;
    z-index: 200;
    height: 100vh;
    width: 8vw;
    /* background-color: #ebebeb; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.no-webp .image1 {
    background-image: url("../../../../assets/images/background-sx.jpg");
  }
  
  .webp .image1{
    background-image: url("../../../../assets/images/background-sx.webp");
  }

  .no-webp .image2 {
    background-image: url("../../../../assets/images/background-cx.jpg");
  }
  
  .webp .image2{
    background-image: url("../../../../assets/images/background-cx.webp");
  }

  .no-webp .image3 {
    background-image: url("../../../../assets/images/background-dx.jpg");
  }
  
  .webp .image3 {
    background-image: url("../../../../assets/images/background-dx.webp");
  }

.carousel-arrows {
    color: #ebebeb !important;
    font-size: 7vw;
    cursor: pointer;
    display: none;
}

.item {
    width: 100%;
    height: 100vh;
    display: flex;
    /* padding-top: 170px; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-title {
    /* box-shadow: 0px 0px 10px 10px #00000013; */
    font-family: 'HNM';
    /* background-color: #00000013; */
    font-size: 5.2vw;
    text-align: center;
    width: 80vw;
    margin-top: 15vh !important;
}

.main-title-mobile {
    display: none;
}

.description {
    /* box-shadow: 0px 0px 10px 10px #00000011; */
    width: 65vw;
    /* background-color: #00000011; */
    padding-top: 6vh;
    font-family: 'Raleway';
    letter-spacing: 1px;
    text-align: center;
    line-height: 13vh;
    font-size: 2vw;
}

/* SMALL MOBILE */

@media only screen and (max-width: 400px) {
    .description {
        padding-top: 5vh;
        line-height: 9vw !important;
    }
    .main-title-mobile {
        margin-top: -6vh;
        margin-bottom: 0vw !important;
    }
}

/* MOBILE */

@media only screen and (max-width: 767px) and (orientation: portrait) {
    .side {
        width: 14vw;
    }
    .carousel-arrows {
        display: none;
        margin-top: 39vh;
        color: #ebebeb !important;
        font-size: 13vw;
        cursor: pointer;
    }
    .main-title-mobile {
        width: 90% !important;
        font-family: 'HNM';
        margin-bottom: 8vh;
        margin-top: 17vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 9.5vw;
        font-weight: 900;
        text-align: center;
    }
    .main-title {
        display: none;
    }
    .description {
        width: 82vw !important;
        text-justify: newspaper !important;
        font-family: 'Raleway';
        font-size: 4.7vw !important;
        text-align: center;
        font-weight: 500;
        line-height: 7.6vw;
        letter-spacing: 1px;
    }
    .centered-button {
        width: 58% !important;
        height: 9vh !important;
        right: 21% !important;
        left: 21% !important;
        top: unset !important;
        bottom: 11.7vh !important;
    }
    /* .button-icon {
        margin-right: 8% !important;
        margin-left: 10% !important;
        font-size: 4.8vw !important;
        color: white !important;
    } */
    .discover-text {
        font-size: 5vw !important;
    }
    /* .button-icon {
        margin-right: 8% !important;
        margin-left: 10% !important;
        font-size: 6vw !important;
        color: white !important;
    } */
    .bottom-dots-container {
        height: 60px !important;
    }
    .dot {
        margin: 0 4vw !important;
    }
}

/* TABLET */

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .side {
        width: 14vw;
    }
    .carousel-arrows {
        margin-top: 39vh;
        color: #ebebeb !important;
        font-size: 9vw;
        cursor: pointer;
        display: none;
    }
    .main-title-mobile {
        width: 90% !important;
        font-family: 'HNM';
        margin-bottom: 6vh;
        margin-top: calc(130px + 2vh) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        /* padding: 0 15%; */
        /* height: 182px; */
        font-size: 10vw;
        text-align: center;
    }
    .main-title {
        display: none;
    }
    .description {
        width: 83vw !important;
        text-justify: newspaper !important;
        font-family: 'Raleway';
        font-size: 4.5vw !important;
        text-align: center;
        line-height: 7.1vw;
    }
    .centered-button {
        width: 45% !important;
        height: 8vh !important;
        right: 27.5% !important;
        left: 27.5% !important;
        top: 76vh !important;
    }
    /* .button-icon {
        margin-right: 8% !important;
        margin-left: 10% !important;
        font-size: 4.8vw !important;
        color: white !important;
    } */
    .discover-text {
        font-family: 'HN';
        font-size: 3vw !important;
        letter-spacing: 2.5px;
        font-weight: medium;
        transition: letter-spacing 0.4s;
        /* color: black; */
    }
}

/* SUPER WIDE DESKTOP */

/* { */

@media (min-aspect-ratio: 19/9) and (orientation: landscape) {
    .main-title {
        font-size: 3.9vw;
    }
    .description {
        width: 55vw !important;
        text-justify: newspaper !important;
        font-family: 'Raleway';
        font-size: 1.5vw !important;
        text-align: center;
        line-height: 4.5vw;
    }
}

/* } */

.color-0 {
    background-color: #1b0208;
}

.color-1 {
    background-color: #0f0f0f;
}

.color-2 {
    /* height: calc(110vh); */
    background-color: #000000;
}

.bottom-dots-container {
    position: absolute;
    z-index: 200;
    width: 100%;
    height: 80px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* background-color: #ebebeb; */
}

.dot {
    box-shadow: 0px 0px 10px -3px black;
    margin: 0 20px;
    /*    MARGINE PUNTI    */
    background-color: transparent;
    width: 12px !important;
    height: 12px !important;
    border: solid 1px #838383;
    border-radius: 50%;
    transition: background-color 0.5s, border 0.5s;
}

.dot-active {
    background-color: white !important;
    border: solid 1px white;
}

.dot-container {
    padding: 10px;
    /* width: 50px;
    height: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* .button-icon {
    margin-right: 9%;
    margin-left: 12%;
    font-size: 1.75vw;
    color: white !important;
} */

.centered-button {
    position: absolute;
    background-color: #000000c7;
    color: white;
    border: solid 1.7px white;
    width: auto;
    padding: 2vh 2.5%;
    /* height: 7vh; */
    top: calc(87vh - 80px);
    right: calc(40% - 2.5%);
    left: calc(40% - 2.5%);
    border-radius: 100px;
    z-index: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 3px 1px 20px -6px rgba(200, 200, 200, 0.123);
    cursor: pointer;
    transition: 0.4s;
}

.centered-button:hover {
    /* background: linear-gradient(to right, #3f0815, #580a1d, #690c22, #580a1d, #3f0815); */
    background-color: #8a1329ea;
    color: white !important;
    border: solid 1.7px #8a1329ea;
}

.centered-button:active {
    box-shadow: 3px 1px 10px -6px rgba(200, 200, 200, 0);
    background: linear-gradient(to right, #2f050f, #3f0815, #420916, #3f0815, #2f050f);
    border: solid #31050f;
    color: rgb(155, 155, 155) !important;
    letter-spacing: 11px !important;
}

.discover-text {
    font-family: 'HN';
    font-size: 1.55vw;
    letter-spacing: 2.5px;
    font-weight: medium;
    transition: letter-spacing 0.4s;
    /* color: black; */
}